var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { shrink: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Opgeleverd Matrix")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "justify-end": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "", "justify-space-between": "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "info-icon",
                                attrs: { shrink: "" }
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openReadMoreDialog(
                                                        "matrix"
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [_vm._v("info_outline")]
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [_c("span", [_vm._v("klik voor meer info")])]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.isLoadingFilters
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoadingFilters
                  ? [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "align-end": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", "mb-2": "" } },
                            [
                              _c("PeriodFilter", {
                                attrs: {
                                  to: _vm.filters.to,
                                  from: _vm.filters.from
                                },
                                on: { change: _vm.applyDates }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "with-border",
                                attrs: {
                                  items: _vm.breakdowns,
                                  "item-value": "key",
                                  "item-text": "name",
                                  "hide-details": "",
                                  label: "Breakdown"
                                },
                                model: {
                                  value: _vm.filters.breakdown,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "breakdown", $$v)
                                  },
                                  expression: "filters.breakdown"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "with-border",
                                attrs: {
                                  items: _vm.userTypes,
                                  "item-value": "key",
                                  "item-text": "name",
                                  "hide-details": "",
                                  label: "Actiehouder"
                                },
                                model: {
                                  value: _vm.filters.actor,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "actor", $$v)
                                  },
                                  expression: "filters.actor"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "align-end": "" } },
                        [
                          _vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("ReportOrganizationFilter", {
                                    attrs: {
                                      label: "Bureaus",
                                      placeholder: "",
                                      cssClass: "with-border",
                                      canSelectAll: true
                                    },
                                    model: {
                                      value: _vm.filters.organizations,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "organizations",
                                          $$v
                                        )
                                      },
                                      expression: "filters.organizations"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                            [
                              _c("ReportDepartmentFilter", {
                                attrs: {
                                  label: "Afdelingen",
                                  placeholder: "",
                                  cssClass: "with-border",
                                  multiple: false
                                },
                                model: {
                                  value: _vm.filters.department,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "department", $$v)
                                  },
                                  expression: "filters.department"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                            [
                              _c("ReportTypeFilter", {
                                attrs: {
                                  label: "Rapporttypes",
                                  placeholder: "",
                                  cssClass: "with-border",
                                  canSelectAll: true
                                },
                                model: {
                                  value: _vm.filters.types,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "types", $$v)
                                  },
                                  expression: "filters.types"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                            [
                              _c("WorkFlowFilter", {
                                attrs: {
                                  label: "Workflow",
                                  placeholder: "",
                                  cssClass: "with-border",
                                  canSelectAll: true
                                },
                                model: {
                                  value: _vm.filters.workflows,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "workflows", $$v)
                                  },
                                  expression: "filters.workflows"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                            [
                              _c("OpnameVariantFilter", {
                                attrs: {
                                  label: "Opname variant",
                                  placeholder: "",
                                  cssClass: "with-border",
                                  canSelectAll: true
                                },
                                model: {
                                  value: _vm.filters.opname_variants,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filters,
                                      "opname_variants",
                                      $$v
                                    )
                                  },
                                  expression: "filters.opname_variants"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                            [
                              _c("ManagementClassFilter", {
                                attrs: {
                                  label: "Rapportage classificatie",
                                  placeholder: "",
                                  cssClass: "with-border",
                                  canSelectAll: true
                                },
                                model: {
                                  value: _vm.filters.management_info_classes,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filters,
                                      "management_info_classes",
                                      $$v
                                    )
                                  },
                                  expression: "filters.management_info_classes"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right",
                              attrs: { sm12: "" }
                            },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "true",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.applyFilters()
                                    }
                                  }
                                },
                                [_vm._v(" Pas filters toe ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c("v-flex", {
                      staticClass: "separator",
                      attrs: { xs12: "" }
                    })
                  ],
                  1
                ),
                _vm.isLoading
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isLoading
                  ? [
                      _c(
                        "div",
                        { staticClass: "overflow-auto" },
                        [
                          _vm.items && _vm.items.length
                            ? _c("TreeView", {
                                attrs: {
                                  first: true,
                                  items: _vm.items,
                                  columns: _vm.columns
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      !_vm.items || !_vm.items.length
                        ? _c("div", { staticClass: "mt-3" }, [
                            _c("span", [
                              _vm._v(
                                'Druk op de knop "Pas filters toe" om de resultaten te bekijken.'
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }