import ReportTypeFilter from '@/components/filters/report-type-filter/ReportTypeFilter.vue';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import { Component, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { AxiosError, AxiosResponse } from 'axios';
import { TreeViewColumn } from '@/components/tree-view/TreeView';
import ErrorHandler from '@/support/ErrorHandler';
import { ReportType } from '@/models/ReportType';
import { Organization } from '@/models/Organization';
import { UserType } from '@/models/User';
import { ReportTypeObject } from '@/views/ReportStats/ReportStats';
import { Rpc } from '@/models/Rpc';
import { Department } from '@/models/Department';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { OpgeleverdMatrixInfo } from '@/support/Info';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';
import { ReportTypes } from '@/support/ReportTypes';
import { ProductionStatusEnum } from '@/items/ProductionStatus';
import { OpnameVariantEnum } from '@/items/OpnameVariant';
import { cloneDeep } from 'lodash';
import { ManagementInfoClassEnum } from '@/models/Report';

@Component<OpgeleverdMatrix>({
  components: {
    ReportTypeFilter,
    PeriodFilter,
  },
})
export default class OpgeleverdMatrix extends AbstractMoreInfoDialog {
  public $pageTitle = 'Opgeleverd Matrix';

  protected filters: OpgeleverdMatrixFilters = {
    from: '',
    to: '',
    types: [],
    organizations: [],
    department: '',
    actor: 'expert',
    breakdown: 'type_report',
    workflows: [],
    opname_variants: [],
    management_info_classes: [],
  };

  protected bacTypes: ReportTypes[] = [
    ReportTypes.BEZWAAR_2021,
    ReportTypes.ADVIESRAPPORT_BEZWAAR_HOORZITTING,
    ReportTypes.ADVIESRAPPORT_BEZWAAR_ADDENDUM,
    ReportTypes.ADVIESRAPPORT_BEZWAAR,
    ReportTypes.BEZWAAR_BAC_2,
  ];

  protected filterMap: {[key: string]: any} = {
    bac_doorlooptijd_zonder_opname: {
      opname_variants: [OpnameVariantEnum.ZONDER_OPNAME],
      management_info_classes: [ManagementInfoClassEnum.BEZWAAR],
      types: this.bacTypes,
    },
    bac_doorlooptijd_opname: {
      opname_variants: [OpnameVariantEnum.OPNAME],
      management_info_classes: [ManagementInfoClassEnum.BEZWAAR],
      types: this.bacTypes,
    },
    bac_doorlooptijd_hoorzitting: {
      opname_variants: [OpnameVariantEnum.HOORZITTING],
      management_info_classes: [ManagementInfoClassEnum.BEZWAAR],
      types: this.bacTypes,
    },
    bac_doorlooptijd_calculatie: {
      management_info_classes: [ManagementInfoClassEnum.BEZWAAR],
      types: [ReportTypes.BEZWAAR_BAC_2_CALCULATIE],
    },
    ambtelijk_doorlooptijd_zonder_opname: {
      opname_variants: [OpnameVariantEnum.ZONDER_OPNAME],
      management_info_classes: [ManagementInfoClassEnum.BEZWAAR],
      types: [ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK, ReportTypes.BEZWAAR_AMBTELIJK_2],
    },
    ambtelijk_doorlooptijd_opname: {
      opname_variants: [OpnameVariantEnum.OPNAME],
      management_info_classes: [ManagementInfoClassEnum.BEZWAAR],
      types: [ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK, ReportTypes.BEZWAAR_AMBTELIJK_2],
    },
    ambtelijk_doorlooptijd_hoorzitting: {
      opname_variants: [OpnameVariantEnum.HOORZITTING],
      management_info_classes: [ManagementInfoClassEnum.BEZWAAR],
      types: [ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK, ReportTypes.BEZWAAR_AMBTELIJK_2],
    },
    ambtelijk_doorlooptijd_calculatie: {
      management_info_classes: [ManagementInfoClassEnum.BEZWAAR],
      types: [ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE],
    },
  };

  // organizations
  protected organizations: Organization[] = [];

  protected selectedOrganizations: string[] = [];

  protected moreInfo: MoreInfo[] = OpgeleverdMatrixInfo;

  // report types
  protected reportTypes: ReportTypeObject[] = [];

  protected departments: Department[] = [];

  protected selectAllOrganizations = false;

  protected selectAllReportTypes = false;

  // user types
  protected userTypes: UserType[] = [
    {
      key: 'expert',
      name: 'Opnemer',
    },
    {
      key: 'second_expert',
      name: 'Deskundige (indien anders dan opnemer)',
    },
    {
      key: 'tc',
      name: 'Technisch Coordinator',
    },
  ];

  // breakdown
  protected breakdowns: {[key: string]:string }[] = [
    {
      name: 'Type, Dossier',
      key: 'type_report',
    },
    {
      name: 'Type, Actiehouder, Dossier',
      key: 'type_employee_report',
    },
    {
      name: 'Actiehouder, Type, Dossier',
      key: 'employee_type_report',
    },
  ];

  protected columns: TreeViewColumn[] = [
    {
      key: 'first',
      name: '',
      style: 'min-width: 200px;',
      visible: true,
    },
    {
      key: 'delivered_reports',
      name: '# Rapporten opgeleverd',
      class: 'text-xs-right',
      visible: true,
    },
    {
      key: 'first_time_right',
      name: '% FTR (gem.)',
      class: 'text-xs-right col-ftr col-doorlooptijd',
      postfix: '%',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'doorlooptijd',
      name: 'Doorlooptijd in dagen (gem.)',
      class: 'text-xs-right col-performance',
      postfix: 'd.',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(number);
      },
    },
    {
      key: 'doorlooptijd_percentage',
      name: '% Doorlooptijd binnen norm (gem.)',
      class: 'text-xs-right col-performance',
      postfix: '%',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'costs',
      name: 'Kosten per dossier (gem.)',
      class: 'text-xs-right col-costs col-euro',
      style: 'min-width: 110px;',
      visible: this.$store.state.Auth.is_rockefeller,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'damages_count',
      name: 'Opgenomen schades (gem.)',
      class: 'text-xs-right',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(number);
      },
    },
    {
      key: 'damages_causaal_count',
      name: 'Causale schades',
      class: 'text-xs-right',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'damages_not_causaal_count',
      name: 'Niet Causale schades',
      class: 'text-xs-right',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'damages_identiek_nam',
      name: 'Identiek NAM',
      class: 'text-xs-right',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'damages_identiek_img',
      name: 'Identiek IMG',
      class: 'text-xs-right',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'payout_total_damage',
      name: '€ per schade (gem.)',
      class: 'text-xs-right',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'payout_total_report',
      name: '€ per rapport (gem.)',
      class: 'text-xs-right',
      visible: true,
      style: 'min-width: 110px;',
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
  ];

  // loaders
  protected isLoading = false;

  protected isLoadingFilters = true;

  protected items: RealtimeManagementStruct[] = [];

  public mounted() {
    this.setFiltersFromQuery();
    this.initialize();
  }

  protected async initialize() {
    this.isLoadingFilters = true;

    this.emitBreadcrumb();
    await this.getOrganizations();
    await this.getDepartments();
    await this.getReportTypes();

    this.isLoadingFilters = false;
  }

  protected setFiltersFromQuery(): void {
    const filterKey = this.$route.query.filter;
    const from = this.$route.query.from;
    const till = this.$route.query.till;
    const filterMap = cloneDeep(this.filterMap[filterKey as string]);

    const lastDayOfMonth = DateTime.local().daysInMonth;

    this.filters.from = from ? from as string : `${DateTime.local().toFormat('yyyy-MM')}-01`;
    this.filters.to = till ? till as string : `${DateTime.local().toFormat('yyyy-MM')}-${lastDayOfMonth}`;

    this.filters = { ...this.filters, ...filterMap };
  }

  protected async getDepartments() {
    await new Department()
      .all()
      .then((departments: Department[]) => {
        this.departments = departments;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getOrganizations() {
    await new Organization()
      .all()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getReportTypes() {
    await new ReportType()
      .all()
      .then((response: ReportTypeObject[]) => {
        this.reportTypes = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected applyFilters() {
    this.isLoading = true;
    const payload = {
      signature: 'management-reports:delivered',
      body: this.filters,
    };

    new Rpc()
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.items = response.data;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected applyDates(from: string, to: string): void {
    this.filters.from = from;
    this.filters.to = to;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapportage / Opgeleverd Matrix' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  @Watch('selectAllOrganizations')
  public selectAllOrganizationsChanged(to: any, from: any) {
    if (this.selectAllOrganizations) {
      this.$set(this, 'selectedOrganizations', this.organizations.map((organization) => organization.id));
    } else {
      this.$set(this, 'selectedOrganizations', []);
    }
  }

  @Watch('selectAllReportTypes')
  public selectAllReportTypesChanged(to: any, from: any) {
    if (this.selectAllReportTypes) {
      this.$set(this, 'selectedReportTypes', this.reportTypes.map((reportType) => reportType.uuid));
    } else {
      this.$set(this, 'selectedReportTypes', []);
    }
  }
}

interface RealtimeManagementStruct {
  id?: string;
  active: boolean;
  name: string;
  data: RealtimeManagementData;
  children: RealtimeManagementStruct[];
}

interface RealtimeManagementData {
  planned_reports: string;
  delivered_reports: string;
  first_time_right: string;
  doorlooptijd: string;
  doorlooptijd_percentage: string;
  costs: string;
}

interface OpgeleverdMatrixFilters {
  from: string;
  to: string;
  types: string[];
  department: string;
  actor: string;
  breakdown: string;
  workflows: string[];
  opname_variants: string[];
  management_info_classes: string[];
  organizations: string[];
}
